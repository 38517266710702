import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/Layout"
import HeroHeader from "../components/HeroHeader"
import SEO from "../components/seo"

const HospedagemPage = () => {
  const {
    hospedagemCpanel,
    hospedagemOtimizadores,
    hospedagemSSD,
    hospedagemSpam,
    hospedagemPHP,
    hospedagemSuporte,
    hospedagemSoftaculous,
    hospedagemDesenvolvimento,
  } = useStaticQuery(graphql`
    query {
      hospedagemCpanel: file(
        relativePath: { eq: "layout/icones/cpanel-192.jpg" }
      ) {
        id
        childImageSharp {
          fluid(maxWidth: 192, maxHeight: 192, quality: 72) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      hospedagemOtimizadores: file(
        relativePath: { eq: "layout/icones/otimizadores-192.jpg" }
      ) {
        id
        childImageSharp {
          fluid(maxWidth: 192, maxHeight: 192, quality: 92) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      hospedagemSSD: file(relativePath: { eq: "layout/icones/ssd-192.jpg" }) {
        id
        childImageSharp {
          fluid(maxWidth: 192, maxHeight: 192, quality: 92) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      hospedagemSpam: file(relativePath: { eq: "layout/icones/spam-192.jpg" }) {
        id
        childImageSharp {
          fluid(maxWidth: 192, maxHeight: 192, quality: 72) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      hospedagemPHP: file(relativePath: { eq: "layout/icones/php-192.jpg" }) {
        id
        childImageSharp {
          fluid(maxWidth: 192, maxHeight: 192, quality: 92) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      hospedagemSuporte: file(
        relativePath: { eq: "layout/icones/suporte-192.jpg" }
      ) {
        id
        childImageSharp {
          fluid(maxWidth: 192, maxHeight: 192, quality: 92) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      hospedagemSoftaculous: file(
        relativePath: { eq: "layout/softaculous.png" }
      ) {
        id
        childImageSharp {
          fixed(pngQuality: 100, width: 230, height: 160) {
            ...GatsbyImageSharpFixed
          }
        }
      }

      hospedagemDesenvolvimento: file(
        relativePath: { eq: "layout/portfolio.png" }
      ) {
        id
        childImageSharp {
          fixed(pngQuality: 100, width: 318, height: 182) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Hospedagem" />

      <HeroHeader
        heroMode={"is-dark"}
        heroClass={"is-triangulo"}
        heroSize={"is-small"}
        navActive={"hospedagem"}
      >
        <div className="container has-text-centered">
          <div className="block is-uppercase is-titulo-hero">
            <h1 className="title is-3">
              Hospedagem de sites e e-mail profissional
            </h1>
            <h2 className="subtitle is-5 has-text-primary">
              Estabilidade e atendimento personalizado
            </h2>
          </div>
          <div className="block">
            <div className="columns">
              <div className="column">
                <div className="card is-card-planos">
                  <header className="card-header">
                    <p className="card-header-title hero is-primary">Básico</p>
                  </header>
                  <div className="card-content">
                    <div className="content ">
                      <h1 className="title has-text-black">R$25,75/mês</h1>
                      <h2 className="subtitle has-text-primary is-6">
                        no plano anual
                      </h2>
                      <table>
                        <tbody>
                          <tr>
                            <td>10GB Armazenamento</td>
                          </tr>
                          <tr>
                            <td>50GB largura de Banda</td>
                          </tr>
                          <tr>
                            <td>4 Bancos MySQL</td>
                          </tr>
                          <tr>
                            <td>20 Contas de Email</td>
                          </tr>
                          <tr>
                            <td>200 envios de emails/hora</td>
                          </tr>
                          <tr>
                            <td>Trimestral – R$ 89,70</td>
                          </tr>
                          <tr>
                            <td>Semestral – R$ 162,00</td>
                          </tr>
                          <tr>
                            <td>Anual – R$ 309,00</td>
                          </tr>
                        </tbody>
                      </table>
                      <a
                        href="/sac/cart.php?a=add&pid=3"
                        className="button is-warning native-cta"
                      >
                        Contrate Agora!
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="column">
                <div className="card is-card-planos">
                  <header className="card-header">
                    <p className="card-header-title hero is-primary">Empresa</p>
                  </header>
                  <div className="card-content">
                    <div className="content ">
                      <h1 className="title has-text-black">R$53,50/mês</h1>
                      <h2 className="subtitle has-text-primary is-6">
                        no plano anual
                      </h2>
                      <table>
                        <tbody>
                          <tr>
                            <td>20GB Armazenamento</td>
                          </tr>
                          <tr>
                            <td>100GB largura de Banda</td>
                          </tr>
                          <tr>
                            <td>5 Bancos MySQL</td>
                          </tr>
                          <tr>
                            <td>30 Contas de Email</td>
                          </tr>
                          <tr>
                            <td>250 envios de emails/hora</td>
                          </tr>
                          <tr>
                            <td>Trimestral – R$ 168,70</td>
                          </tr>
                          <tr>
                            <td>Semestral – R$ 330,00 </td>
                          </tr>
                          <tr>
                            <td>Anual – R$ 642,00</td>
                          </tr>
                        </tbody>
                      </table>
                      <a
                        href="/sac/cart.php?a=add&pid=4"
                        className="button is-warning native-cta"
                      >
                        Contrate Agora!
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="column">
                <div className="card is-card-planos">
                  <header className="card-header">
                    <p className="card-header-title hero is-primary">
                      Corporativo
                    </p>
                  </header>
                  <div className="card-content">
                    <div className="content ">
                      <h1 className="title has-text-black">R$83,25/mês</h1>
                      <h2 className="subtitle has-text-primary is-6">
                        no plano anual
                      </h2>
                      <table>
                        <tbody>
                          <tr>
                            <td>30GB Armazenamento</td>
                          </tr>
                          <tr>
                            <td>200GB largura de Banda</td>
                          </tr>
                          <tr>
                            <td>15 Bancos MySQL</td>
                          </tr>
                          <tr>
                            <td>50 Contas de Email</td>
                          </tr>
                          <tr>
                            <td>300 envios de emails/hora</td>
                          </tr>
                          <tr>
                            <td>Trimestral – R$ 288,70</td>
                          </tr>
                          <tr>
                            <td>Semestral – R$ 567,00 </td>
                          </tr>
                          <tr>
                            <td>Anual – R$ 999,00</td>
                          </tr>
                        </tbody>
                      </table>
                      <a
                        href="/sac/cart.php?a=add&pid=8"
                        className="button is-warning native-cta"
                      >
                        Contrate Agora!
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </HeroHeader>

      <section id="vantagens" className="container m-b-lg p-md">
        <div className="block">
          <h1 className="title is-3 has-text-centered p-lg">
            Vantagens em escolher nossos planos!
          </h1>
          <div className="columns">
            <div className="column">
              <div className="card bm-card-equal-height">
                <div className="card-content">
                  <p className="title is-4">
                    Não perca tempo precioso esperando...
                  </p>
                  <p>
                    Seu site pronto para ser hospedado após a confirmação de
                    pagamento.
                  </p>
                </div>
              </div>
            </div>

            <div className="column">
              <div className="card bm-card-equal-height">
                <div className="card-content">
                  <p className="title is-4">Estabilidade Garantida</p>
                  <p>
                    Aqui você não compartilha o servidor com milhares de outros
                    sites. Nossos servidores são dimensionados e ocupados de
                    modo a ter processamento, estabilidade e segurança ideais.
                  </p>
                </div>
              </div>
            </div>

            <div className="column">
              <div className="card bm-card-equal-height">
                <div className="card-content">
                  <p className="title is-4">Mais fácil do que nunca!</p>
                  <p>
                    Se a sua preocupação é o processo de mudança de provedor,
                    mas dispõe de cPanel no seu atual hosting, nós realizamos
                    todo o processo de transferência para a SH2.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="block has-text-centered">
          <h1 className="title is-4 is-uppercase p-sm">
            Todos os planos incluem
          </h1>

          <div className="columns">
            <div className="column">
              <figure className="image is-96x96 is-inline-block">
                <Img
                  fluid={hospedagemCpanel.childImageSharp.fluid}
                  alt="Painel cPanel"
                />
              </figure>
              <h1 className="title is-5 is-marginless">Painel cPanel</h1>
              <p>
                Você com controle total usando cPanel. Gerencie facilmente tudo
                da sua hoespedagem: seus sites, arquivos, bancos de dados e
                e-mails.
              </p>
            </div>
            <div className="column">
              <figure className="image is-96x96 is-inline-block">
                <Img
                  fluid={hospedagemOtimizadores.childImageSharp.fluid}
                  alt="Otimizadores de Site"
                />
              </figure>
              <h1 className="title is-5 is-marginless">Otimizadores</h1>
              <p>
                Faça a otimização dos arquivos dos seus sites diretamente pelo
                painel de controle. Arquivos mais leves, sites mais rápidos.
              </p>
            </div>
            <div className="column">
              <figure className="image is-96x96 is-inline-block">
                <Img
                  fluid={hospedagemSSD.childImageSharp.fluid}
                  alt="Discos SSD"
                />
              </figure>
              <h1 className="title is-5 is-marginless">Disco SSD</h1>
              <p>
                Todos nos nossos servidores já estão atualizados com discos SSD.
                Isso significa mais velocidade para o seu site e outros
                serviços.
              </p>
            </div>
          </div>
          <div className="columns">
            <div className="column">
              <figure className="image is-96x96 is-inline-block">
                <Img
                  fluid={hospedagemSpam.childImageSharp.fluid}
                  alt="Sistema de Anti-Spam"
                />
              </figure>
              <h1 className="title is-5 is-marginless">Anti-Spam</h1>
              <p>
                Filtro poderoso contra recebimento de SPAMs. Sistema atualizado
                com frequência para evitar ao máximo mensagens indesejadas.
              </p>
            </div>
            <div className="column">
              <figure className="image is-96x96 is-inline-block">
                <Img
                  fluid={hospedagemPHP.childImageSharp.fluid}
                  alt="Painel Multi PHP"
                />
              </figure>
              <h1 className="title is-5 is-marginless">Multi PHP</h1>
              <p>
                Escolha a versão do PHP para cada aplicação dentro da sua
                hospedagem. Customização completa, escolha quais pacotes ativar.
              </p>
            </div>
            <div className="column">
              <figure className="image is-96x96 is-inline-block">
                <Img
                  fluid={hospedagemSuporte.childImageSharp.fluid}
                  alt="Suporte de verdade"
                />
              </figure>
              <h1 className="title is-5 is-marginless">Suporte de verdade</h1>
              <p>
                Equipe especializada que sempre vai te ajudar a encontrar uma
                solução. Respostas rápidas e informativas.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="hero is-small is-light is-bold p-t-xl p-b-xl">
        <div className="hero-body">
          <div className="container">
            <div className="columns">
              <div className="column is-two-thirds">
                <h1 className="title">Instalação Rápida</h1>
                <h2 className="subtitle">
                  Sua plataforma preferida com alguns cliques
                </h2>
                <p>
                  Com o <strong>Softaculous</strong> você tem as principais
                  plataformas de blog, portais, fóruns, wikis e muito mais com
                  uma mínima configuração necessária. O sistema corta a
                  burocracia e deixa tudo certo para você logar e começar a usar
                  o que precisar na sua hospedagem.
                </p>
              </div>
              <div className="column has-text-centered">
                <Img
                  fixed={hospedagemSoftaculous.childImageSharp.fixed}
                  alt="Principais programas no Softaculous"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        id="promo"
        className="hero is-small is-dark is-background-promo p-t-xl p-b-xl"
      >
        <div className="hero-body">
          <div className="container">
            <div className="columns">
              <div className="column has-text-centered">
                <Img
                  fixed={hospedagemDesenvolvimento.childImageSharp.fixed}
                  alt="Desenvolvimento de lojas e sites"
                />
              </div>
              <div className="column is-two-thirds">
                <h1 className="title">Precisa por sua ideia em prática?</h1>
                <h2 className="subtitle">
                  Contamos com uma equipe completa para desenvolver seu projeto.
                </h2>
                <p>
                  Conheça nossas soluções para{" "}
                  <Link to="/loja" className="is-destaque is-loja">
                    Lojas Virtuais{" "}
                  </Link>
                  e{" "}
                  <Link to="/site" className="is-destaque is-website">
                    Websites
                  </Link>
                  .
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="hero is-small is-primary is-bold p-md">
        <div className="hero-body">
          <div className="container">
            <div className="columns">
              <div className="column is-two-thirds">
                <h1 className="title is-4 is-uppercase">Ainda em dúvida?</h1>
                <p className="subtitle p-t-md">
                  Entre em contato para que um dos nossos consultores possam
                  ajudá-lo a escolher o plano de hospedagem adequado à suas
                  necessidades.
                </p>
                <p className="subtitle p-t-xxs">
                  Também oferecemos planos personalizados, consulte.
                </p>
              </div>
              <div className="column is-vertical-center">
                <Link
                  to="/contato"
                  className="button is-medium native-cta is-pulled-right"
                >
                  Enviar mensagem
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default HospedagemPage
